<template>
  <div class="card-group mt-4 mb-7">
    <div
      class="card"
      v-for="(option, index) in layouts.settings.options"
      :key="index"
      @click="onClickCard(index)"
    >
      <div class="transform-box">
        <div class="card-title ml-1">COUPON</div>
        <div class="coupon-number px-1">{{ layouts.data.couponCode }}</div>
      </div>
      <coupon-s-v-g
        class="svg-shadow"
        :primary-color="
          index === layouts.data.checkIndex ? 'white' : 'var(--theme_primary)'
        "
        :active="index === layouts.data.checkIndex"
      ></coupon-s-v-g>
      <div class="right-card">
        <div class="percentage">
          <div
            class="percentage-value"
            :style="index === layouts.data.checkIndex ? '' : 'color: var(--theme_primary)'"
          >
            {{ parseCouponRate(option.value) }}
          </div>
          <div
            class="percentage-unit pl-2"
            :style="index === layouts.data.checkIndex ? 'color: white' : ''"
          >
            <span class="f-s-30">{{ dataSources.couponType === 1 ? '%' : '$' }}</span>
            <div class="f-s-16 mt-n2">OFF</div>
          </div>
        </div>
        <!--        <div-->
        <!--          class="checkbox-input&#45;&#45;square"-->
        <!--          :style="-->
        <!--            index === layouts.data.checkIndex ? '' : 'background: #F4F5F9'-->
        <!--          "-->
        <!--        >-->
        <!--          <v-icon class="checkbox-input&#45;&#45;status" color="var(&#45;&#45;theme_secondary)"-->
        <!--            >{{ index === layouts.data.checkIndex ? "check" : "" }}-->
        <!--          </v-icon>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'
import CouponSVG from '@/components/collection/CouponSVG'

export default {
  name: 'CouponCardGroup',
  components: {
    CouponSVG
  },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      dataSources: {
        // type为1 对应百分比， type为2  对应为$
        couponType: null
      },
      layouts: {
        data: {
          checkIndex: 0,
          couponCode: ''
        },
        settings: {
          options: [
            {
              value: 0
            }
          ]
        }
      }
    }
  },
  watch: {
    '$store.getters.getCouponDetails': {
      handler (val) {
        this.getCouponDetails()
      }
    }
  },
  mounted () {
    this.getCouponDetails()
  },
  methods: {
    parseCouponRate (val) {
      if (this.assists.tool.isNotEmpty(val) && this.dataSources.couponType === 1) {
        return (-Number(val) * 100).toFixed(val.slice(5, 7) ? 2 : 0)
      } else {
        return -Number(val)
      }
    },
    getCouponDetails () {
      const res = this.$store.getters.getCouponDetails
      const couponCode = this.$store.getters.getCouponCode
      this.$emit('couponDetails', res)
      this.layouts.data.couponCode = couponCode || 'N/A'
      this.dataSources.couponType = res[0].type
      this.layouts.settings.options[0].value = res[0].value
    },
    onClickCard (index) {
      this.layouts.data.checkIndex = index
      this.$emit('change', index)
    }
  }
}
</script>

<style lang="sass" scoped>
.card-group
  font-family: 'Montserrat', sans-serif

  .card
    width: 100%
    position: relative

    & svg
      width: 100%
      display: block

    .transform-box
      position: absolute
      top: 35%
      left: -5%
      transform: rotate(-90deg)
      transform-origin: center center
      z-index: 1
      display: flex
      flex-direction: column
      align-items: center

    &-title
      font-size: 16px
      font-weight: 900
      letter-spacing: 6px

    .coupon-number
      font-size: 10px
      color: var(--theme_primary)
      border: 1px solid var(--theme_primary)
      border-radius: 2px
      letter-spacing: 3px

    .right-card
      position: absolute
      right: 0
      top: 0
      height: calc(100% - 5px)
      width: 70%
      display: flex
      justify-content: space-between
      align-items: center

      .percentage
        height: 100%
        display: flex
        font-weight: 900
        justify-content: center
        width: calc(100% - 24px)

        &-value
          margin: auto 0
          font-size: 64px
          color: white

        &-unit
          margin: auto 0

      .checkbox-input--square
        display: flex
        justify-content: center
        align-items: center
        width: 24px
        height: 24px
        background-color: rgba(255, 255, 255, 0.4)

      .checkbox-input--status
        font-size: 16px

  .card + .card
    margin-top: 18px

  .svg-shadow
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.15))
</style>
