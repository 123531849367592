<template>
  <div>
    <!-- 为了拼接上nav的light背景 -->
    <div class="montage-background" />

    <div class="px-6">
      <v-card class="load-card elevation-1 py-2 px-4">
        <v-row no-gutters class="height-full">
          <v-col
            v-for="(item, index) in layouts.settings.headPanel"
            :key="index"
            cols="6"
            class="d-flex flex-column justify-center align-center"
          >
            <div class="card-text">{{ item.title }}</div>
            <div class="card-value">{{ layouts.data.headerValue[item.fieldKey] }}</div>
          </v-col>
        </v-row>
      </v-card>

      <div class="prompt-text">
        Congratulations, your are qualified for a discount on your loan balance.
      </div>

      <coupon-card-group @couponDetails="getCompareTransactionFee"/>

      <v-card class="amount-card elevation-0">
        <v-simple-table class="amount-table" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th>Current</th>
                <th>Revised</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'item-total': item.name === 'Total' }"
                v-for="(item, index) in paymentFeeSetting"
                :key="item.name"
              >
                <td
                  class="name-td"
                  :style="index !== paymentFeeSetting.length - 2 ? 'border: none' : ''"
                >
                  {{ item.name }}
                </td>
                <td
                  class="current-td"
                  :style="index !== paymentFeeSetting.length - 2 ? 'border: none' : ''"
                  :class="item.original !== item.current ? 'line-through' : ''"
                >
                  {{ assists.currency.format(item.original) }}
                </td>
                <td
                  :style="index !== paymentFeeSetting.length - 2 ? 'border: none' : ''"
                >
                  {{ assists.currency.format(item.current) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <div class="footer">
        <wap-btn :height="50" :block-control="true" @click="onClickContinue"
          >Continue</wap-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Tool, Currency } from '@/assets/js/util'
import WapBtn from '@/components/base/WapBtn'
import CouponCardGroup from '@/components/collection/CouponCardGroup'
import { OnlineApi } from '@/api'

export default {
  components: {
    CouponCardGroup,
    WapBtn
  },
  data () {
    return {
      assists: {
        tool: new Tool(),
        currency: new Currency()
      },
      layouts: {
        data: {
          list: [
            {
              name: 'Principal',
              fieldKey: 'principal',
              original: 0,
              current: 0
            },
            {
              name: 'Finance Fee',
              fieldKey: 'financeFee',
              original: 0,
              current: 0
            },
            {
              name: 'Late Fee',
              fieldKey: 'lateFee',
              original: 0,
              current: 0
            },
            {
              name: 'NSF Fee',
              fieldKey: 'NSFFee',
              original: 0,
              current: 0
            },
            {
              name: 'Unpaid Fee',
              fieldKey: 'unpaidFee',
              original: 0,
              current: 0
            },
            {
              name: 'Bank Fee',
              fieldKey: 'bankFee',
              original: 0,
              current: 0
            },
            {
              name: 'Refuse Fee',
              fieldKey: 'refuseFee',
              original: 0,
              current: 0
            },
            {
              name: 'Total',
              fieldKey: 'total',
              original: 0,
              current: 0
            }
          ],
          checkIndex: 0,
          headerValue: {
            loanNo: '',
            bankName: '',
            loanAmount: '',
            routingNo: '',
            effectiveDate: '',
            accountNo: ''
          }
        },
        settings: {
          headPanel: [
            {
              title: 'Loan #',
              fieldKey: 'loanNo'
            },
            {
              title: 'Bank Name',
              fieldKey: 'bankName'
            },
            {
              title: 'Loan Amount',
              fieldKey: 'loanAmount'
            },
            {
              title: 'Routing No',
              fieldKey: 'routingNo'
            },
            {
              title: 'Effective Date',
              fieldKey: 'effectiveDate'
            },
            {
              title: 'Account No',
              fieldKey: 'accountNo'
            }
          ]
        }
      }
    }
  },
  computed: {
    paymentFeeSetting () {
      const init = [...this.layouts.data.list]
      const result = []
      init.map((item) => {
        if (item.original !== 0) {
          result.push(item)
        }
      })
      this.$store.commit('setTransactionFeeList', [...result])
      return result
    }
  },
  watch: {
    '$store.getters.getCollectionLoanNo': {
      handler (val) {
        this.layouts.data.headerValue.loanNo = val
        this.getPaymentOption()
        this.getCompareTransactionFee()
      }
    }
  },
  mounted () {
    this.layouts.data.headerValue.loanNo = this.$store.getters.getCollectionLoanNo

    if (this.assists.tool.isNotEmpty(this.layouts.data.headerValue.loanNo)) {
      this.getPaymentOption()
    }
  },
  methods: {
    getPaymentOption () {
      OnlineApi.getPaymentOption(
        { loanNo: this.layouts.data.headerValue.loanNo },
        (res) => {
          if (this.assists.tool.isNotEmpty(res)) {
            res.routingNo = '*****' + res.routingNo.substring(res.routingNo.length - 4)
            res.accountNo = '******' + res.accountNo.substring(res.accountNo.length - 4)
            res.loanAmount = this.assists.currency.format(res.loanAmount)
            Object.assign(this.layouts.data.headerValue, res)
          }
        },
        (err) => {
          this.$store.commit('setPopupInformation', {
            message: err.message
          })
        }
      )
    },
    getCompareTransactionFee (data) {
      const input = {
        loanNo: this.$store.getters.getCollectionLoanNo,
        // type为1 对应百分比， type为2  对应为$
        couponDetails: data
      }
      OnlineApi.compareTransactionFee(
        input,
        (res) => {
          if (this.assists.tool.isNotEmpty(res)) {
            Object.keys(res).forEach((item) => {
              this.layouts.data.list.map(list => {
                if (list.fieldKey === item) {
                  list = Object.assign(list, res[item])
                }
              })
            })
          }
        },
        (err) => {
          this.$store.commit('setPopupInformation', {
            message: err.message
          })
        }
      )
    },
    onClickContinue () {
      this.$router.push('/coupon/schedule')
    }
  }
}
</script>

<style lang='sass' scoped>
.montage-background
  width: 100%
  height: 75px
  margin-top: -1px // 为了使连接处完全没有间隙，mt了1px，对应的height也+了1px
  background-color: var(--theme_secondary)
  border-radius: 0 0 16px 16px

.align-left
  text-align: left
  line-height: 24px
.align-right
  text-align: right

.load-card
  height: fit-content
  border-radius: 8px
  margin-top: -80px
  & .card-text
    color: #9CA0AB
    font-size: 12px
  & .card-value
    color: #000410
    font-size: 16px
    font-weight: bold
    width: 100%
    text-align: center
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis  /* 超出文本展示为省略号 */

.prompt-text
  font-size: 12px
  color: #464C5B
  margin-top: 32px
  margin-bottom: 24px

.active
  background: var(--theme_secondary)
  color: white!important

.amount-card
  border-radius: 8px
  & .amount-table
    padding: 0 8px 8px 8px
    background-color: #F4F5F9
    & th
      height: 30px
      text-align: right !important
      font-weight: 500
      color: black !important
      font-size: 10px
    & td
      height: 24px
      text-align: right
      font-size: 12px
      font-weight: 500
    & .name-td
      text-align: left
      font-weight: 500
      color: #464C5B
    & .current-td
      color: #9CA0ABFF
    & .line-through
      text-decoration: line-through
    & .item-total
      & :nth-child(1)
        padding-top: 4px
        font-size: 16px
        color: #000410
      & :nth-child(2)
        padding-top: 4px
        font-size: 16px
      & :nth-child(3)
        padding-top: 4px
        font-size: 16px
        color: var(--theme_primary)
</style>
